export type AlertType = "danger" | "warning" | "success" | "info";

export const removeAlerts = () => {
  document
    .querySelectorAll<HTMLElement>(
      ".alert:not([data-sticky='true']):not([data-listened])",
    )
    .forEach((alert) => {
      if (alert.dataset.listened || alert.dataset.sticky === "true") return;

      // Read the timeout from the data-timeout attribute
      // Set a default timeout of 10000ms (10 seconds)
      const timeout = parseInt(alert.dataset.timeout || "10000", 10);

      setTimeout(() => {
        alert.querySelector<HTMLButtonElement>(".btn-close")?.click();
      }, timeout);

      alert.dataset["listened"] = "true";
    });
};

/**
 * Display a message to user
 * @params {string} message - Message to show
 * @params {string} type - Type of notification
 */
export const showMessage = (
  message: string,
  type: AlertType = "danger",
  isSticky = false,
) => {
  const container = document.querySelector<HTMLElement>("#flash-container");
  if (!container)
    throw Error("flash-container not found, message cannot be shown!");

  container.innerHTML = `
    <div
      class="alert alert-${type} alert-dismissible fade show shadow mt-4 fw-medium"
      role="alert"
      ${isSticky ? "data-sticky='true'" : ""}
    >
      <span>${message}</span>
      <button
        type="button"
        class="btn-close"
        data-bs-dismiss="alert"
        aria-label="Close"
      ></button>
    </div>
  `;

  removeAlerts();
};

/**
 * Clear the chosen types of notifications
 * @params {string} type - Type of notification
 */
export const clearMessage = (type = "danger") => {
  document.querySelectorAll(`.${type}-alert`).forEach((alert) => {
    alert.querySelector<HTMLButtonElement>(".btn-close")?.click();
  });
};
